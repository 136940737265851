<template>
  <div class="voucherCode">
    <van-tabs v-model:active="active" type="card" @change="tabChange">
      <van-tab title="入馆凭证码" name="入馆凭证码"></van-tab>
      <van-tab title="观影凭证码" name="观影凭证码"></van-tab>
    </van-tabs>
    <div class="content" v-if="list.length > 0">
      <div v-if="list.length && show" class="failure">
        <img
          :src="list[0].img"
          v-if="list[0] && list[0].img"
          alt=""
          class="qrcode"
        />
        <VueQr
          v-else
          class="qrcode"
          :logoSrc="require('@/assets/images/home/personal/user_logo2.png')"
          text=""
          :size="190"
          :margin="5"
          :logoScale="0.3"
        ></VueQr>
        <div class="text" v-if="!(list[0] && list[0].img) && state">
          {{ state }}
        </div>
        <div class="text" v-else-if="time > expirationTime">失效</div>
        <div class="left" @click="onLeft">
          <img v-if="index <= 1" src="@/assets/images/left_dis.png" alt="" />
          <img v-else src="@/assets/images/left.png" alt="" />
        </div>
        <div class="right" @click="onRight">
          <img
            v-if="index >= length"
            src="@/assets/images/right_dis.png"
            alt=""
          />
          <img v-else src="@/assets/images/right.png" alt="" />
        </div>
      </div>
      <div class="info">
        <p class="name">
          {{ list[0] && list[0].name }}
        </p>
        <p class="which">
          第<span>{{ index }}/{{ length }}</span
          >张
        </p>
        <div class="refresh" @click="onRefresh">
          <img src="@/assets/images/refresh.png" alt="" />
        </div>
      </div>
      <p class="remarks">
        为保障公众权益，杜绝有偿代约行为，请观众凭已预约的动态二维码进馆参观，截图二维码无效
      </p>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import { voucherCodeTicket, voucherCode } from '@/api/MyTicket';
export default {
  data() {
    return {
      time: 0,
      timer: null,
      show: true,
      index: 1,
      length: 0,
      state: '',
      expirationTime: 60,
      active: '入馆凭证码',
      list: [],
      signCodeApi: null,
      qrCodeApi: null,
      type: '',
    };
  },
  props: {
    orderNo: {
      type: String,
      default: () => '',
    },
    qrcode: {
      type: Array,
      default: () => [],
    },
  },
  components: { VueQr },
  created() {
    console.log(this.qrcode);
    this.type = this.$route.query.type;
    this.signCodeApi = voucherCodeTicket;
    this.qrCodeApi = voucherCode;
    this.tabChange();
  },
  mounted() {},
  watch: {
    index() {
      this.onRefresh();
    },
  },
  methods: {
    tabChange() {
      this.index = 1;
      this.onRefresh();
    },
    async getQrcode() {
      this.list = [];
      let { seat, name } = this.qrcode[this.index - 1];
      let codeApi = null;
      if (this.active === '入馆凭证码') {
        codeApi = this.signCodeApi;
      } else {
        codeApi = this.qrCodeApi;
      }
      let { data, msg } = await codeApi({
        orderNo: this.orderNo,
        seat: seat,
      });
      this.code = (data && data.img) || '';
      this.state = msg;
      if (data) {
        this.list.push({ name, img: data.img });
      } else {
        this.list.push({ name, img: '' });
      }
      this.length = this.qrcode.length;
      this.state = msg === '操作成功' ? '' : msg;
    },
    async onRefresh() {
      clearInterval(this.timer);
      this.time = 0;
      this.show = false;
      await this.getQrcode();
      this.$nextTick(() => {
        this.setTimer();
        this.show = true;
      });
    },
    setTimer() {
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
    },
    onLeft() {
      if (this.index > 1) {
        this.index--;
      } else {
        this.$toast('已经是第一张');
      }
    },
    onRight() {
      if (this.index < this.length) {
        this.index++;
      } else {
        this.$toast('已经是最后一张');
      }
    },
  },
  //销毁定时器
  unmounted() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.voucherCode {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;
  ::v-deep .van-tabs {
    .van-tabs__nav--card {
      margin: 0;
      border-color: #076cfa;
      border-right-color: #076cfa !important;
      .van-tab--card {
        color: #0b6cf9;
        border-color: #076cfa;
      }
      .van-tab--active {
        background-color: #076cfa;
        color: #fff;
      }
    }
  }
  .content {
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .failure {
      position: relative;
      height: 190px;
      margin: 5px 0;
      width: 100%;
      .text {
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .qrcode,
      .text {
        position: absolute;
        width: 190px;
        height: 190px;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
      }
      .left,
      .right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 50px;
          height: 50px;
        }
      }
      .left {
        left: 0px;
      }
      .right {
        right: 0px;
      }
    }
    .info {
      position: relative;
      width: 100%;
      height: 54px;
      > p {
        font-size: 14px;
        text-align: center;
      }
      .name {
        font-size: 18px;
        color: #076cfa;
        font-weight: 600;
      }
      .which {
        margin-top: 4px;
        span {
          font-size: 18px;
          color: #333333;
          font-weight: 600;
        }
      }
      .refresh {
        position: absolute;
        right: 0;
        top: 0;
        img {
          width: 96px;
          height: 54px;
        }
      }
    }
    .tips {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        text-align: center;
        color: #999;
      }
      .red_color {
        color: #fd4d4f;
      }
    }
    .remarks {
      font-size: 13px;
      color: #f9871e;
      background-color: #fefbf1;
      border: 1px solid #ffb500;
      padding: 8px;
      line-height: 150%;
      span {
        color: #fd4d4f;
      }
    }
  }
}
</style>
