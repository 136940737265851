<template>
  <div class="ticketDetails">
    <TicketList
      :ticketList="ticketList"
      :quantitys="quantitys"
      :isClick="false"
      :badgeShow="badgeShow"
    />
    <div class="box">
      <div class="information" v-if="type === '影票购买详情'">
        <div>座位信息</div>
        <p>已选座位：{{ data.chooseSeats }}</p>
      </div>
      <template v-if="[0, 1].includes(data.status) && qrcode.length">
        <VoucherCodeFilm
          :orderNo="orderNo"
          :qrcode="qrcode"
          v-if="type === '影票购买详情'"
        />
        <VoucherCode
          :title="title"
          :orderNo="orderNo"
          :qrcode="qrcode"
          :type="type"
          :isRefresh="[0, 1].includes(data.status)"
          @onRefresh="onRefresh"
          v-else
        />
      </template>
      <MyBox
        :type="type === '影票购买详情' ? '影票联系人' : '联系人'"
        title="联系人"
        :status="data.status"
        :appointedByList="appointedByList"
        v-if="appointedByList.length"
        @onCancel="onCancel"
      />
      <MyBox
        :type="type === '影票购买详情' ? '影票随行人员' : '随行人员'"
        title="随行人员"
        :status="data.status"
        :accompanyingList="accompanyingList"
        v-if="accompanyingList.length"
        :isRefund="type === '儿童科学乐园门票预约详情' ? false : true"
        @onCancel="onCancel"
      />
      <div class="information" v-if="orderList.length">
        <div>订单信息</div>
        <p v-for="(item, index) in orderList" :key="index">
          <span>{{ item.name }}：{{ data[item.key] }}</span>
        </p>
      </div>
      <div class="information" v-if="data.status === 1 && writeOffList.length">
        <div>核销信息</div>
        <p v-for="(item, index) in writeOffList" :key="index">
          <span>{{ item.name }}：{{ data[item.key] }}</span>
        </p>
      </div>
      <div class="information" v-if="data.status === -1 && defaultList.length">
        <div>违约信息</div>
        <p v-for="(item, index) in defaultList" :key="index">
          <span>{{ item.name }}：{{ data[item.key] }}</span>
        </p>
      </div>
    </div>
    <div class="bottom_box">
      <van-button
        color="#fd4d4f"
        plain
        v-if="
          (data.status === 0 || data.auditStatus === 0) &&
          ['门票详情', '儿童科学乐园门票预约详情'].includes(type)
        "
        @click="toRefund"
        >整单退票</van-button
      >
    </div>
  </div>
</template>
<script>
import TicketList from '@/components/TicketList.vue';
import MyBox from '@/components/MyBox.vue';
import VoucherCode from '@/components/VoucherCode.vue';
import VoucherCodeFilm from '@/components/VoucherCodeFilm.vue';
import {
  playgroundDetail,
  playgroundRefund,
  ticketsDetail,
  ticketRefund,
  movieFind,
  movieRefundAll,
  movieRefund,
} from '@/api/MyTicket';
export default {
  name: 'TicketDetails',
  data() {
    return {
      type: '',
      orderNo: null,
      ticketList: [{}],
      quantitys: [],
      title: '',
      appointedByList: [],
      accompanyingList: [],
      data: {},
      orderList: [],
      writeOffList: [],
      defaultList: [],
      infoApi: null,
      refundApi: null,
      qrcode: [],
      badgeShow: false,
    };
  },
  components: { TicketList, MyBox, VoucherCode, VoucherCodeFilm },
  created() {
    let { type, orderNo } = this.$route.query;
    this.type = type;
    this.orderNo = orderNo;
    if (this.type === '影票购买详情') {
      this.title = '观影凭证码';
      this.infoApi = movieFind;
      this.refundApi = movieRefundAll;
      this.quantitys = [
        { name: '观影时间', key: 'gysj' },
        { name: '购票数', key: 'votes', right: 'prices' },
      ];
      this.orderList = [
        { name: '订单编号', key: 'orderNo' },
        { name: '预约方式', key: 'platform' },
      ];
      this.defaultList = [{ name: '违约原因', key: 'breakReason' }];
    } else {
      this.title = '入馆凭证码';
      this.orderList = [
        { name: '订单编号', key: 'orderNo' },
        { name: '预约时间', key: 'createTime' },
      ];
      this.defaultList = [{ name: '违约原因', key: 'remark' }];
      if (this.type === '门票详情') {
        this.infoApi = ticketsDetail;
        this.refundApi = ticketRefund;
        this.ticketList[0].title = '温州科技馆门票';
        this.quantitys = [
          { name: '进馆时间', key: 'cgsj' },
          { name: '预约总人数', key: 'totalNum' },
        ];
      } else if (this.type === '儿童科学乐园门票预约详情') {
        this.infoApi = playgroundDetail;
        this.refundApi = playgroundRefund;
        this.ticketList[0].title = '儿童科学乐园门票';
        this.quantitys = [
          { name: '游玩时间', key: 'cgsj' },
          { name: '预约总人数', key: 'totalNum' },
        ];
      }
    }
    this.getInfo();
  },
  mounted() {},
  methods: {
    async getInfo() {
      const arr = [
        '',
        '身份证',
        '护照',
        '往来港澳通行证/港澳居民来往内地通行证',
        '往来台湾通行证/台湾居民来往大陆通行证',
        '外国人永久居留证（Foreign Permanent Resident ID Card）',
      ];
      let { data } = await this.infoApi({ orderNo: this.orderNo });
      let {
        visitDate,
        startTime,
        endTime,
        status,
        totalNum,
        orderItems,
        documentNumber,
        showTicketReorderInfo,
        votes,
        prices,
        showTicketOrderTVOList,
        showTicketOrderInfoList,
        auditStatus,
        payTime,
      } = data;
      if (['门票详情', '儿童科学乐园门票预约详情'].includes(this.type)) {
        orderItems.map((item) => {
          item.documentTypeText = arr[item.documentType];
        });
        this.accompanyingList = orderItems.filter(
          (res) => res.documentNumber !== documentNumber
        );
        this.appointedByList = orderItems.filter(
          (v) => v.documentNumber === documentNumber
        );
        if (startTime && endTime) {
          this.ticketList[0].cgsj =
            visitDate +
            ' ' +
            startTime.split(' ')[1] +
            '-' +
            endTime.split(' ')[1];
        } else {
          this.ticketList[0].cgsj = visitDate;
        }
        this.ticketList[0].totalNum = totalNum + '人';
        this.ticketList[0].auditStatus = auditStatus;
        if (status === 2) {
          const arr = [{ name: '退票时间', key: 'refundTime' }];
          this.orderList = this.orderList.concat(arr);
        }
      } else if (this.type === '影票购买详情') {
        let { dayTime, startTime, endTime, filmName } = showTicketReorderInfo;
        this.ticketList[0].prices = prices;
        this.ticketList[0].votes = votes + '张';
        this.ticketList[0].showTicketOrderTVOList = showTicketOrderTVOList;
        this.ticketList[0].gysj = dayTime + ' ' + startTime + '-' + endTime;
        this.ticketList[0].title = filmName;
        switch (status) {
          case '待核销':
            status = 0;
            break;
          case '已核销':
            status = 1;
            break;
          case '已取消':
            status = 2;
            break;
          case '已违约':
            status = -1;
            break;
          default:
            break;
        }
        if (payTime) {
          const arr = [{ name: '支付时间', key: 'payTime' }];
          this.orderList = this.orderList.concat(arr);
        }
        this.appointedByList = [data];
        this.accompanyingList = showTicketOrderInfoList;
      }
      this.ticketList[0].status = status;
      data.status = status;
      this.data = data;
      if ([0, 1].includes(data.status)) {
        this.getQrcode();
      }
      this.badgeShow = true;
    },
    async getQrcode() {
      this.qrcode = [];
      if (this.type === '影票购买详情') {
        let { seat, contactsName, showTicketOrderInfoList } = this.data;
        let arr = [{ name: contactsName, seat }];
        for (let i = 0; i < showTicketOrderInfoList.length; i++) {
          arr.push({
            seat: showTicketOrderInfoList[i].seat,
            name: showTicketOrderInfoList[i].contactsName,
          });
        }
        this.qrcode = arr;
      } else {
        let arr = [];
        for (let i = 0; i < this.data.orderItems.length; i++) {
          let { name, id } = this.data.orderItems[i];
          arr.push({
            name,
            orderItemId: id,
          });
        }
        this.qrcode = arr;
      }
    },
    onRefresh() {
      this.getQrcode();
    },
    //退票
    toRefund() {
      this.$dialog
        .confirm({
          message: '是否确定要退此票,此操作不能返回。',
        })
        .then(() => {
          this.refundApi({ orderNo: this.orderNo }).then((res) => {
            if (res.code === 200) {
              this.$notify({
                type: 'success',
                message: res.msg,
              });
              this.$store.state.include = [];
              this.$router.go(-1);
            } else {
              this.$notify({
                type: 'danger',
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    //单个退票
    onCancel(item) {
      this.$dialog
        .confirm({
          // title: "标题",
          message: '是否确定要退此票,此操作不能返回。',
        })
        .then(() => {
          if (this.type === '影票购买详情') {
            movieRefund({ orderNo: this.orderNo, orderInfoId: item.id }).then(
              (res) => {
                if (res.code === 200) {
                  this.$notify({
                    type: 'success',
                    message: res.msg,
                  });
                  this.getInfo();
                } else {
                  this.$notify({
                    type: 'danger',
                    message: res.msg,
                  });
                }
              }
            );
          } else {
            this.refundApi({ orderNo: this.orderNo, itemId: item.id }).then(
              (res) => {
                if (res.code === 200) {
                  this.$notify({
                    type: 'success',
                    message: res.msg,
                  });
                  this.getInfo();
                } else {
                  this.$notify({
                    type: 'danger',
                    message: res.msg,
                  });
                }
              }
            );
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.ticketDetails {
  background: #f5f6fa;
  min-height: calc(100vh - 112px);
  padding: 10px 0 56px;
  .box {
    padding: 0 10px;
    > div {
      margin-top: 10px;
    }
    .information {
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      div {
        margin: 0 0 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececec;
        font-weight: 500;
        color: #333333;
        font-size: 14px;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 24px;
      }
    }
  }
  .bottom_box {
    height: 46px;
    padding: 0 20px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .van-button {
      width: 70px;
      height: 26px;
      line-height: 26px;
      font-size: 12px;
      padding: 0;
      margin-left: 10px;
    }
  }
}
</style>
