<template>
  <div class="voucherCode">
    <div class="title">{{ title }}</div>
    <div class="content">
      <!-- <p class="serialNo">预约序号：{{ orderNo }}</p> -->
      <div v-if="qrcode.length && show" class="failure">
        <img :src="code" v-if="code" alt="" class="qrcode" />
        <VueQr
          v-else
          class="qrcode"
          :logoSrc="require('@/assets/images/home/personal/user_logo2.png')"
          text=""
          :size="190"
          :margin="5"
          :logoScale="0.3"
        ></VueQr>
        <div class="text" v-if="!code && state">
          {{ state }}
        </div>
        <div class="text" v-else-if="time > expirationTime">失效</div>
        <div class="left" @click="onLeft">
          <img
            v-if="this.index <= 1"
            src="@/assets/images/left_dis.png"
            alt=""
          />
          <img v-else src="@/assets/images/left.png" alt="" />
        </div>
        <div class="right" @click="onRight">
          <img
            v-if="this.index >= this.length"
            src="@/assets/images/right_dis.png"
            alt=""
          />
          <img v-else src="@/assets/images/right.png" alt="" />
        </div>
      </div>
      <div class="info">
        <p class="name">{{ qrcode[index - 1].name }}</p>
        <p class="which">
          第<span>{{ index }}/{{ length }}</span
          >张
        </p>
        <div class="refresh" @click="onRefresh" v-if="isRefresh">
          <img src="@/assets/images/refresh.png" alt="" />
        </div>
      </div>
      <p class="remarks">
        为保障公众权益，杜绝有偿代约行为，请观众凭<span
          >已预约的动态二维码{{ type === "门票详情" ? "或身份证" : "" }}</span
        >进馆参观，截图二维码无效
      </p>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr/src/packages/vue-qr.vue";
import {
  ticketOrderQrcode,
  voucherCode,
  playgroundQrcode,
} from "@/api/MyTicket";
export default {
  data() {
    return {
      time: 0,
      timer: null,
      show: true,
      index: 1,
      length: 0,
      code: "",
      state: "",
      qrcodeApi: null,
      expirationTime: 20,
    };
  },
  props: {
    type: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    qrcode: {
      type: Array,
      default: () => [],
    },
    orderNo: {
      type: String,
      default: () => "",
    },
    isRefresh: {
      type: Boolean,
      default: () => true,
    },
  },
  components: { VueQr },
  created() {
    if (this.type === "门票详情") {
      this.expirationTime = 10;
      this.qrcodeApi = ticketOrderQrcode;
    } else if (this.type === "影票购买详情") {
      this.qrcodeApi = voucherCode;
    } else if (this.type === "儿童科学乐园门票预约详情") {
      this.expirationTime = 10;
      this.qrcodeApi = playgroundQrcode;
    }
    this.getQrcode();
    this.setTimer();
    this.index = 1;
    this.length = this.qrcode.length;
  },
  mounted() {},
  watch: {
    index() {
      this.onRefresh();
    },
  },
  methods: {
    async getQrcode() {
      let { orderItemId, seat } = this.qrcode[this.index - 1];
      if (["门票详情", "儿童科学乐园门票预约详情"].includes(this.type)) {
        let { data, msg } = await this.qrcodeApi({
          orderNo: this.orderNo,
          orderItemId: orderItemId,
        });
        this.code = data;
        this.state = msg;
      } else if (this.type === "影票购买详情") {
        this.qrcodeApi = voucherCode;
        let { data, msg } = await this.qrcodeApi({
          orderNo: this.orderNo,
          seat: seat,
        });
        this.code = (data && data.img) || "";
        this.state = msg;
      }
    },
    onRefresh() {
      clearInterval(this.timer);
      this.time = 0;
      this.setTimer();
      this.show = false;
      // this.$emit("onRefresh");
      this.getQrcode();
      this.$nextTick(() => {
        this.show = true;
      });
    },
    setTimer() {
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
    },
    onLeft() {
      if (this.index > 1) {
        this.index--;
      } else {
        this.$toast("已经是第一张");
      }
    },
    onRight() {
      if (this.index < this.length) {
        this.index++;
      } else {
        this.$toast("已经是最后一张");
      }
    },
  },
  //销毁定时器
  unmounted() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.voucherCode {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;
  .title {
    border-radius: 5px 0 0 5px;
    min-width: 32px;
    background: linear-gradient(
      180deg,
      rgba(11, 108, 249, 1) 1%,
      rgba(91, 183, 255, 1) 100%
    );
    font-weight: 650;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    writing-mode: tb-rl;
    line-height: 32px;
    letter-spacing: 8px;
    padding: 20px 0;
  }
  .content {
    width: 100%;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .failure {
      position: relative;
      height: 190px;
      margin: 5px 0;
      width: 100%;
      .text {
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .qrcode,
      .text {
        position: absolute;
        width: 190px;
        height: 190px;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
      }
      .left,
      .right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 50px;
          height: 50px;
        }
      }
      .left {
        left: 0px;
      }
      .right {
        right: 0px;
      }
    }
    .info {
      position: relative;
      width: 100%;
      height: 54px;
      > p {
        font-size: 14px;
        text-align: center;
      }
      .name {
        font-size: 18px;
        color: #076cfa;
        font-weight: 600;
      }
      .which {
        margin-top: 4px;
        span {
          font-size: 18px;
          color: #333333;
          font-weight: 600;
        }
      }
      .refresh {
        position: absolute;
        right: 0;
        top: 0;
        img {
          width: 96px;
          height: 54px;
        }
      }
    }
    .serialNo {
      font-size: 14px;
      color: #666;
    }
    .tips {
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        text-align: center;
        color: #999;
      }
      .red_color {
        color: #fd4d4f;
      }
    }
    .remarks {
      font-size: 14px;
      color: #f9871e;
      background-color: #fefbf1;
      border: 1px solid #ffb500;
      padding: 8px;
      line-height: 150%;
      span {
        color: #fd4d4f;
      }
    }
  }
}
</style>
